.Projects-title {
    color: rgb(67, 195, 255);
    font-size: 3em;
}

.Projects-container {

}

.Project {

}

.Project-Photo {
    width: 80%;    
    height: 40vh;
    margin: 15px auto;
    text-align: center;
}

.Project-Photo img {
    height: 100%;
    margin: auto;
}


.Project-title {
    text-align: center;
}

.Project-title a {
    color: rgb(67, 195, 255);
}

.Project-description {
    margin-top: 10px;
    font-size: 1.2em;
    height: 25vh;
}

.Project-Navbuttons {
    display: flex;
    margin: auto;
    width: fit-content;
    column-gap: 10px;
}

.closed-circle {
    background-color: rgb(67, 195, 255);
}

.open-circle {
    
}

.closed-circle, .open-circle {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid rgb(67, 195, 255);
    cursor: pointer;
}