#pathfinder-body {
    margin: 10px 5px 0px 5px;
}

.pathfinder-section {
    height: 150px;
}

.pathfinder-section-item {
    width: 30%;
    height: 135px; 
    display: inline-block;
    margin: 5px;
}

.pathfinder-button {
    margin-top: 3px;
    padding: 5px;
    color: white;
    font-size: 20px;

    background-color: rgb(49, 182, 226);
    border-radius: 10px;
}

.node {
    border: 1px solid black;
    margin: 0px;
    height: 25px;
    width: 25px;
}

.blank {
    background-color: white;
}

.wall {
    background-color: black;
}

.start {
    background-color: rgb(11, 218, 11);
    cursor:grab;
}

.start:active {
    cursor: grabbing;
}

.end {
    background-color: red;
    cursor: grab;
}

.end:active {
    cursor: grabbing
}

.visited {
    background-color: rgb(49, 182, 226);
}

.solution {
    background-color: yellow;
}