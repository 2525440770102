* {
    margin: 0;
    padding: 0;
    color: white;
}

body, .flex {
    height: 100vh;
    width: 100%;
    background-color: #0e0f0e;
}
.flex {
    display: flex;
}

.grid {
    display: grid;
}

.grid-2-col {
    display: grid;
    grid-template-columns: auto auto;
}

.navbar {
    box-sizing: border-box;
    border-right: 2px solid rgba(0, 255, 255, 0.123);
    width: max(15%, 200px);
    height: 100%;
}

.content-container {
    box-sizing: border-box;
    width: calc(100% - max(10%, 200px));
    height: 100vh;
}

.section-container {
    max-width: 1000px;
    max-height: 100vh;
    margin: auto; 
    margin-top: max(12%, 20px); 
}

.navbar-item {
    box-sizing: border-box;
    border: 2px solid white;
    height: 75px;
    letter-spacing: 2px;

    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    cursor: pointer;
}

.navbar-item p {
    color: black;
    font-size: 35px;
    text-shadow: 1px 0 0 white, 0 -1px 0 white, 0 1px 0 white, -1px 0 0 white;
}