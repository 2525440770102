.Experience-Title-Container h1 {
    color: rgb(67, 195, 255);
    font-size: 3em;
}

.Experience-Container {
    display: flex;
    flex-direction: column;
}

.Experience-Company {
    font-size: 3em !important;
    text-align: auto;
    color: red;
}

.Experience-Container-Top {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 20px;
}

.Experience-Context {
    font-size: 1em;
    text-align: left;
    display:flex;
    flex-direction: column;
    row-gap: 20px;
    margin: auto;
}

.Experience-Context div {
    font-size: 1.6em;
}

.Experience-Photo {
    margin-left: auto;
}

.Experience-Photo img {
    width: 100%;
}

.Experience-Description p {
    font-size: 1.2em;
}