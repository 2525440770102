#Short-Description {
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 1em;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

#Short-Description h2 {
    font-size: 2.3em;
}

#Short-Description h2 span {
    color: rgb(67, 195, 255);
    font-size: 1.5em;
}

#Short-Description p {
    font-size: 1.2em;
}

#Photo {
    height: fit-content;
    margin-top: 30px;
    padding-left: 20%;
}

#Photo img {
    width: 80%;
    border-radius: 30px;
}

#Description {
    margin-top: 20px;
    text-align: center;
}